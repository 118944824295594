@use '../../../global/styles/base/sizing' as *;
@use '../../../global/styles/base/responsive';
@use '../../../global/styles/base/colors';
@use '../../../global/styles/base/mixins';

.navContainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  
  // sticky to top of container
  top: 0;
  position: sticky;
  z-index: 80;

  .mobileMenu{
    border-bottom: 1px solid rgba(150,150,150,0.12);

    @include responsive.overWidth(responsive.$desktop-width) {
      display: none;
    }
  }
}

.nav{
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  background: var(--background-color-1);
  padding: 0 $l;
  
  width: 100%;
  border-bottom: 1px solid rgba(150,150,150,0.12);

  .maxWidthContainer{
    max-width: $app-max-width;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .navTop{
    padding: $s $s $s 0;
    padding-right: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo{
      position: relative;
      
      svg{
        color: var(--white-on-dark);
        height: 36px;
        margin: 0;
      }

      .betaTag{
        position: absolute;
        bottom: 0px;
        left: 100%;
        transform: translateX(-50%);
        transform: translateY(-50%);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 2px $xs;
        border: 3px solid var(--background-color-1);
        border-radius: $b-rad;  
        background-color: var(--secondary);
        
        span{
          color: white;
          font-size: 12px;
        }
      }
    }
  }

  .navMid{
    flex: 1;
    padding: $s $ms;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    
    @include responsive.mobile{
      justify-content: end;
    }

    .menuItem{
      height: 36px;
      width: 100%;
      border-radius: $b-rad;
      padding: $ms;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: $s;

      &.active-route, &:hover{
        span{
          color: colors.$text-color-light;
        }
      }

      @include mixins.primaryBackground($showBGOn: ".active-route");
    }

    .raidContainer{
      flex: 1;
      overflow-y: scroll;
    }
  }
  
  .navBottom{
    // height: 64px;
    padding: $s 0;

    @include responsive.mobile{
      display: flex;
      flex-flow: row nowrap;
      column-gap: $s;
    }
  }



  &.collapsed{
    // background: red;
    width: 64px;
  }
}

.navItem{
    
  .navItemBtn{
    @include responsive.belowWidth(responsive.$desktop-width){
      width: 100%;
    }
  }
}