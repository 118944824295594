@use '../../../global/styles/base/sizing' as *;
@use '../../../global/styles/base/responsive';
@use '../../../global/styles/base/colors';

$modal-padding: $ms;
$modal-bg: var(--background-color, white);
$modal-content-seperators: var(--background-color-3, lightgrey);

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);

  display: none;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  &.justifyStart{
    justify-content: flex-start;
  }

  @include responsive.desktop {
    .paddingContainer {
      padding: $xl;
    }
  }

  .modal {
    width: 90vw;
    background: $modal-bg;
    border-radius: $b-rad;

    @include responsive.mobile {
      width: 95vw;
      margin-top: 70px;
      justify-content: flex-start;
    }
  
    @include responsive.desktop {
      max-width: 750px;
    }

    &.tiny {
      max-width: 350px;
    }
  
    &.small {
      max-width: 450px;
    }
  
    &.medium {
      max-width: 600px;
    }
    
    &.max {
      max-width: 88rem;
    }
  }
}

.modalTop {
  border-bottom: 1px solid $modal-content-seperators;
  width: auto;
  padding: $modal-padding;

  @include responsive.mobile {
    padding: $modal-padding;
  }

  .modalTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      margin: 0;
    }
  }

}

.modalMiddle {
  padding: $modal-padding;
  max-height: 80vh;
  overflow-y: scroll;

  .intro {
    line-height: 1.6em;
    margin: 0;
  }
}

.modalBottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  border-top: 1px solid $modal-content-seperators;

  padding: $modal-padding;
  background-color: var(--background-color-1);
  border-radius: 0 0 $b-rad $b-rad;

  .submitContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .skipBtn {
    width: 40%;
    background: rgba(colors.$primary, 0.6);
    margin-left: $s;
    &:hover {
      background: colors.$primary-hover;
    }
  }
}

$transitionDuration: 300ms;
// css-transitions in-out
// black backdrop transition
.backdropAppear {
  display: none !important;
}
.backdropEnter {
  display: flex;
  opacity: 0;
}
.backdropEnterActive {
  opacity: 1;
  display: flex;
  transition: opacity $transitionDuration;
}
.backdropEnterDone {
  // opacity: 1;
  display: flex;
  // transition: opacity $transitionDuration;
}
.backdropExit {
  opacity: 1;
  display: flex;
}
.backdropExitActive {
  opacity: 0;
  display: flex;
  transition: opacity $transitionDuration;
}
.backdropExitDone {
  display: none;
}

// modal transition
.modalEnter {
  opacity: 0;
  transform: scale(0.85);
}
.modalEnterActive {
  opacity: 1;
  transform: scale(1);
  transition: opacity $transitionDuration, transform $transitionDuration;
}
.modalExit {
  opacity: 1;
  transform: scale(1);
}
.modalExitActive {
  opacity: 0;
  transform: scale(0.85);
  transition: opacity $transitionDuration, transform $transitionDuration;
}