@use '../../../global/styles/base/sizing' as *;
@use '../../../global/styles/base/responsive';
@use '../../../global/styles/base/colors';
@use '../../../global/styles/base/mixins';

.footer{
  width: 100%;
  padding: $ml;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
}

.link:hover{
  color: colors.$primary;
}