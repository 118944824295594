// Backgrounds - Light / Dark
$light-1: white;
$light-2: darken($light-1, 3.5%);
$light-3: darken($light-2, 3.5%);
$light-4: darken($light-3, 3.5%);

// $dark-1: #11141A;
$dark-1: #0E1117;
$dark-2: lighten($dark-1, 3%);
$dark-3: lighten($dark-2, 3%);
$dark-4: lighten($dark-3, 3%);


// Colors
$primary: #3D52D5;
$secondary: #10AD9F; // #FF5A5F // #F03A47 // #FF6F59
$tertiary: #DB324D; // #60AFFF
$success: #00CC9B;
$info: #1890FF;
$warning: #FFBC42; // updated
$error: #F5222D; // #FF0035
$inactive: #B9BECA;
$inactive-on-dark: #666;
$white-highlight: #EAEEF2;

// dark mode alternatives
$primary-lighter: #0C77EB;
$purple-lighter: #AE4FEF;


// RGB
$primary-rgb: 61, 82, 213;
$primary-lighter-rgb: 12, 119, 235;
$secondary-rgb: 16, 173, 159;
$tertiary-rgb: 219, 50, 77;
$success-rgb: 0, 204, 155;
$info-rgb: 24, 144, 255;
$warning-rgb: 255, 188, 66;
$error-rgb: 245, 34, 45;
$inactive-rgb: 185, 190, 202;
$inactive-on-dark-rgb: 102, 102, 102;
$white-rgb: 255, 255, 255;

// Hovers
$primary-hover: darken($primary, 5%);
$secondary-hover: darken($secondary, 5%);
$tertiary-hover: darken($tertiary, 5%);
$success-hover: darken($success, 5%);
$info-hover: darken($info, 5%);
$warning-hover: darken($warning, 5%);
$error-hover: darken($error, 5%);
$inactive-hover: darken($inactive, 5%);
$inactive-on-dark-hover: darken($inactive-on-dark, 5%);
$white-highlight-hover: #E3E9ED;

// dark mode alternatives
$primary-hover-lighter: darken($primary-lighter, 5%);

// Greytones
$dark: #33323E;
$medium: #575660;
$medium-light: #adb5bd;
$subtle: #D1D5DB;
$subtle-hover: darken(#D1D5DB, 15%);
$subtle-dark: #3a3c40;
$subtle-dark-hover: lighten(#3a3c40, 15%);

// Text
$text-color-dark: #222;
$text-color-light: #efefef;

$heading-color-dark: #333;
$heading-color-light: #efefef;


// Wow class related
$warrior: darken(#C79C6E, 7%);
$rogue: darken(#F4D35E, 15%);
$hunter: #6BBF59;
$priest: $text-color-light;
$priest-dark: darken($text-color-light, 65%);
$shaman: #2589BD;
$paladin: #EF709D; // darker alt #B5446E
$druid: #F39237;
$mage: #39A9DB;
$warlock: #957FEF;
$deathknight: #AF1B3F;
$demonhunter: #744FC6;
$monk: #06D6A0;
$evoker: #33937F;


.border-primary{ border-color: var(--primary); }
.border-secondary{ border-color: var(--secondary); }
.border-tertiary{ border-color: var(--tertiary); }
.border-inactive{ border-color: var(--inactive); }
.border-weak{ border-color: var(--text-color-weak); }
.border-subtle{
  border-color: var(--subtle);

  &:not(.no-hover):hover {
    border-color: var(--subtle-hover);
  }
}
.border-transparent { border-color: transparent !important; }