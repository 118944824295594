@use '../../../global/styles/base/sizing' as *;
@use '../../../global/styles/base/responsive';

.pageContainer{
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  align-items: center;
  min-height: 100vh;


  @include responsive.mobile {
    overflow-x: hidden;
  }

  .pageContent{
    padding: $l;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    
    @include responsive.mobile {
      padding: $pageContainerPaddingMobile;
    }

    &.normal-width{
      max-width: calc(#{$app-max-width} + (2 * #{$pageContainerPadding}));
    }
    
    &.narrow-width{
      max-width: calc(#{$app-max-width-narrow} + (2 * #{$pageContainerPadding}));
    }

    &.medium-width{
      max-width: calc(#{$app-max-width-medium} + (2 * #{$pageContainerPadding}));
    }

    &.max-width{
      max-width: 100vw;
      padding: 0;
    }
  }
}