@use './colors';
@use './sizing' as *;

@mixin background($inputColor, $showBGOn: true) {
  // $showBGOn: 
  // true (bg + hover) 
  // false (no BG, only hover) 
  // .some-activation-class (bg on &.some-.. + hover)
  
  @if $showBGOn == true {
    background: $inputColor;
  } @else if $showBGOn {
    &#{$showBGOn} {
      background: $inputColor;
    }
  }

  &:hover{
    background: darken($inputColor, 5%);
  }
}

@mixin modifyAllText() {
  h1, h2, h3, h4, h5, h6, p, span{
    @content;
  }
}

@mixin emojiSize($height) {
  span[data-type="emoji"] img{
      height: $height;
  }
}

@mixin primaryBackground($showBGOn: true) {
  @include background(colors.$primary, $showBGOn);
}
@mixin secondaryBackground($showBGOn: true) {
  @include background(colors.$secondary, $showBGOn);
}
@mixin tertiaryBackground($showBGOn: true) {
  @include background(colors.$tertiary, $showBGOn);
}

// doesn't do anything by itself, but will look for wow class classnames on the same element
@mixin classContainer() {
  --inherit-color: var(--white-on-dark);
  &.warrior { @include -classContainerSpecific(colors.$warrior)}
  &.rogue { @include -classContainerSpecific(colors.$rogue)}
  &.hunter { @include -classContainerSpecific(colors.$hunter)}
  &.priest { @include -classContainerSpecific(var(--wow-priest, grey))}
  &.shaman { @include -classContainerSpecific(colors.$shaman)}
  &.paladin { @include -classContainerSpecific(colors.$paladin)}
  &.druid { @include -classContainerSpecific(colors.$druid)}
  &.mage { @include -classContainerSpecific(colors.$mage)}
  &.warlock { @include -classContainerSpecific(colors.$warlock)}
  &.deathknight { @include -classContainerSpecific(colors.$deathknight)}
  &.demonhunter { @include -classContainerSpecific(colors.$demonhunter)}
  &.monk { @include -classContainerSpecific(colors.$monk)}
  &.evoker { @include -classContainerSpecific(colors.$evoker)}
}

@mixin -classContainerSpecific($classColor) {
  --inherit-color: #{$classColor};
}

@mixin tagBG($bgColor, $bg-opacity-hover) {
  color: $bgColor;
  &::before {
    background: $bgColor;
  }
  
  &:hover{
    &::before {
      opacity: $bg-opacity-hover;
    }
  }
}


@mixin onDarkMode {
  :root[data-theme="dark"] & {
    @content;
  }
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin before {
  position: relative;
  & > * {
    z-index: 1;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
    @content;
  }
}